<template lang="pug">
b-modal#cart(
  title="Quote Cart",
  size="xl",
  header-class="justify-content-center",
  hide-footer,
  hide-header,
  centered,
  no-close-on-backdrop,
  no-close-on-esc
)
  b-container.cart-modal-header 
    |
    |
    |
    .d-flex.align-items-center 
      |
      |
      |
      .h4(v-if="!cart_submitted") {{ is_buyer ? $t("quote_modal.purchase_order_heading") : $t("quote_modal.quote_cart") }}
      span.ml-auto.btn-modal-close
        a(href="#", @click="closeModal('cart')")
          b-img(src="@/assets/images/close_modal.svg", width="30") 
    .delete-cart.text-right.mt-3.mb-3(v-if="!cart_submitted")
      a(href="#", @click="emptyTheCart")
        b-img(src="@/assets/images/empty.svg", height="18") 
        span.pl-2 {{ $t("quote_modal.empty_cart") }}
  .cart-modal-body
    b-form(v-if="!cart_submitted")
      .cart-item-list-wrap.d-none.d-md-block 
        |
        |
        |
        b-row.item-list-heading(v-if="cart_items && cart_items.length > 0")
          b-col(cols="4", md="4") 
            |
            |
            |
            .h6 {{ $t("quote_modal.product_title") }}
          b-col(cols="2", md="3") 
            |
            |
            |
            .h6 {{ $t("quote_modal.product_description") }}
          b-col(cols="2", md="2") 
            |
            |
            |
            .h6 {{ $t("quote_modal.sku") }}
          b-col.p-0(cols="1", md="1", v-if="showListPrice") 
            |
            |
            |
            .h6 {{ is_buyer ? $t("quote_modal.price") : $t("quote_modal.list_price") }}
          b-col(:cols="showListPrice ? 2 : 3") 
            |
            |
            |
            .h6 {{ $t("product_inquiry.qty") }}
        b-row.item-list.border-bottom(
          v-for="(item, index) in cart_items",
          :key="index"
        ) 
          |
          |
          |
          b-col.d-flex.item-name(cols="4", md="4") 
            |
            |
            |
            b-img.rounded(:src="getImgUrl(item.image_url)")
            //- b-img.rounded(:src="'https://d24spbd96chgsb.cloudfront.net' + item.image_url")
            span.ml-2 {{ translateText(item, "name") }}
          b-col.list-item(cols="2", md="3") {{ translateText(item, "description") }}
          b-col.list-item(cols="2", md="2") {{ item.sku }}
          b-col.list-item(
            cols="2"
            v-if="showListPrice"
            md="1"
            :class="{ 'p-0': !item.price || item.price === 0 || isNaN(Number(item.price)) }"
            v-text="!item.price || item.price === 0 || isNaN(Number(item.price)) ? $t('global.label.request_quote') : getCurrency + Number(item.price).toFixed(2)"
          )
          b-col.item-qty.d-flex.align-items-center(
            :cols="showListPrice ? 2 : 3"
          ) 
            |
            |
            |
            b-form-input.quantity-input(
              type="number",
              v-model="item.quantity",
              min="1",
              :style="{ width: '70%' }"
            ) 
            b-link.text-secondary.ml-auto(href="#", @click="removeItem(item)")
              b-icon-trash
      .cart-item-list-wrap-mobile.border-top 
        |
        |
        |
        b-row.border-bottom.pt-2.pb-2(
          v-for="(item, index) in cart_items",
          :key="index"
        ) 
          |
          |
          |
          b-col.img-block(cols="3")
            b-img.rounded(:src="getImgUrl(item.image_url)", width="100%")
          b-col.data-block.pl-0(cols="9")
            .item-name {{ item.name }}
            .item-description {{ item.description }}
            b-row.sku-qty
              b-col(cols="7")
                .sku {{ item.sku }}
                .sku.font-weight-bold(v-if="showListPrice") {{ item.price == "Request quote" ? $t("global.label.request_quote") : getCurrency + (Number(item.price) ? Number(item.price).toFixed(2) : "0.00") }}
              b-col.qty.d-flex(cols="5") 
                |
                |
                |
                b-form-input.quantity-input(
                  type="number",
                  size="sm",
                  v-model="item.quantity",
                  min="1",
                  :style="{ width: '70%' }"
                ) 
                b-link.text-secondary.ml-auto(
                  href="#",
                  @click="removeItem(item)"
                )
                  b-icon-trash

      b-container.contact-info-block.mt-4(fluid="md")
        b-row.pl-md-5.pr-md-5
          b-col(cols="12", md="6" v-if="is_buyer") 
            |
            |
            |
            b-form-group#input-group-1
              label(for="purchase_order_number") {{ $t("quote_modal.purchase_order_number") }}
                span.text-danger *
                  | :
              b-form-input#purchase_order_number(
                type="text",
                v-validate="{ required: true }",
                v-model="my_cart_contact.purchase_order_number",
                :state="validateState('purchase_order_number')",
                name="purchase_order_number",
                :placeholder="$t('quote_modal.enter_purchase_order_number')",
                required
              )
              b-form-invalid-feedback(:state="validateState('purchase_order_number')") {{ $t("validation.purchase_order_number") }}
        b-row.pl-md-5.pr-md-5
          b-col(cols="12", md="6") 
            |
            |
            |
            b-form-group#input-group-1
              label(for="inquary-name") {{ $t("quote_modal.name") }}
                span.text-danger *
                | :
              b-form-input#inquary-name(
                type="text",
                v-model="my_cart_contact.name",
                v-validate="{ required: true }",
                :state="validateState('inquary-name')",
                name="inquary-name",
                :placeholder="$t('quote_modal.enter_name')",
                required
              )
              b-form-invalid-feedback(:state="validateState('inquary-name')") {{ $t("validation.name") }}
          b-col(cols="12", md="6") 
            |
            |
            |
            b-form-group#input-group-1
              label(for="inquary-email") {{ $t("quote_modal.enter_email") }}
                span.text-danger *
                | :
              b-form-input#inquary-email(
                type="email",
                v-model="my_cart_contact.email",
                v-validate="{ required: true, email: true }",
                name="inquary-email",
                :state="validateState('inquary-email')",
                :placeholder="$t('quote_modal.email')",
                required
              )
              b-form-invalid-feedback(:state="validateState('inquary-email')") {{ $t("validation.email") }}
          b-col(cols="12", md="6") 
            |
            |
            |
            b-form-group#input-group-1
              label(for="inquary-company") {{ is_buyer ? $t("quote_modal.company_name") : $t("quote_modal.company_hopsital_name") }}
                span.text-danger *
                | :
              b-form-input#inquary-company(
                type="text",
                v-model="my_cart_contact.company",
                v-validate="{ required: true }",
                name="inquary-company",
                :state="validateState('inquary-company')",
                :placeholder="$t('quote_modal.enter_comany_hospital_name')",
                required
              )
              b-form-invalid-feedback(
                :state="validateState('inquary-company')"
              ) {{ $t("validation.company_name") }}
          b-col(cols="12", md="6") 
            |
            |
            |
            b-form-group#input-group-1
              label(for="inquary-phone") {{ $t("quote_modal.phone_number") }}
                span.text-danger *
                | :
              div(v-if="isMedtenCustomer") 
                b-form-input#inquary-phone(
                  type="number",
                  v-model="my_cart_contact.phone",
                  v-validate="{ required: true }",
                  :state="validateState('inquary-phone')",
                  name="inquary-phone",
                  :placeholder="$t('quote_modal.enter_phone_number')",
                  required
                )
                b-form-invalid-feedback(:state="validateState('inquary-phone')") {{ $t("validation.phone_number") }}
              div(v-else) 
                |
                |
                |
                b-form-input#inquary-phone(
                  type="number",
                  v-model="my_cart_contact.phone",
                  v-validate="{ required: true }",
                  :state="validateState('inquary-phone')",
                  name="inquary-phone",
                  :placeholder="$t('quote_modal.enter_phone_number')",
                  required
                )
                b-form-invalid-feedback(:state="validateState('inquary-phone')") {{ $t("validation.phone_number") }}
          b-col(cols="12", md="12") 
            |
            |
            |
            b-form-group#input-group-1
              label(for="inquary-description") {{ is_buyer? $t("quote_modal.special_request") : $t("quote_modal.additional_comments") }}
              b-form-textarea#inquary-description(
                v-model="my_cart_contact.comments",
                v-validate="{ required: false }",
                name="inquary-description",
                :state="validateState('inquary-description')",
                :placeholder="is_buyer?$t('quote_modal.enter_special_request'):$t('quote_modal.description')",
                required
              )
              b-form-invalid-feedback(
                :state="validateState('inquary-description')"
              ) {{ is_buyer ? $t("validation.special_request") : $t("validation.comments") }}
          b-col(cols="12", md="12" v-if="!is_buyer")
            b-form-group
              label {{ $t("quote_modal.preferred_method_of_contact") }} :
              b-form-radio-group#inquary-radio(
                v-model="my_cart_contact.preferred_contact",
                v-validate="{ required: true }",
                name="inquary-radio",
                :state="validateState('inquary-radio')"
              )
                b-form-radio(value="phone") {{ $t("quote_modal.phone") }}
                b-form-radio(value="email") {{ $t("quote_modal.email") }}
                b-form-invalid-feedback(
                  :state="validateState('inquary-radio')"
                ) {{ $t("validation.contact") }}
          b-col(cols="12", md="6" v-if="is_buyer") 
            |
            |
            |
            b-form-group#input-group-1
              label(for="purchase_order_date") {{ $t("quote_modal.purchase_order_date") }}
              b-form-input#purchase_order_date(
                type="date",
                v-model="my_cart_contact.purchase_order_date",
                name="purchase_order_date",
                :placeholder="$t('quote_modal.purchase_order_date')"
              )
          b-col(cols="12", md="6" v-if="is_buyer") 
            |
            |
            |
            b-form-group#input-group-1
              label(for="shipment_terms") {{ $t("quote_modal.shipment_terms") }}
                | :
              b-form-input#shipment_terms(
                type="text",
                v-model="my_cart_contact.shipment_terms",
                :readonly="true"
                name="inquary-shipment_terms",
              )
          b-col(cols="12", md="6" v-if="is_buyer") 
            |
            |
            |
            b-form-group#input-group-1
              label(for="shipment_method") {{ $t("quote_modal.shipment_method") }}
                span.text-danger *
                | :
              b-form-select#shipment_method(size="sm" v-model="my_cart_contact.shipment_method" name="shipment_method" v-validate="{ required: true }" :state="validateState('shipment_method')")
                b-form-select-option(value="") --- Select ---
                b-form-select-option(v-for="p in shipmentMethods" :value="p.name" :key="'option'+p.id") {{p.name}}
              b-form-invalid-feedback(:state="validateState('shipment_method')") {{ $t("validation.shipment_method") }}
          b-col(cols="12", md="12" v-if="is_buyer") 
            |
            |
            |
            b-form-group#input-group-1
              label(for="ship_to") {{ $t("quote_modal.ship_to") }}
                span.text-danger *
                  | :
              b-form-textarea#ship_to(
                v-model="my_cart_contact.ship_to",
                v-validate="{ required: true }",
                name="ship_to",
                :state="validateState('ship_to')",
                :placeholder="$t('quote_modal.enter_ship_to')",
                required
              )
              b-form-invalid-feedback(
                :state="validateState('ship_to')"
              ) {{ $t("validation.ship_to") }}

      b-container.text-center
        b-button.pl-5.pr-5(
          variant="primary",
          @click="submitCart",
          size="md",
          :style="{ ...{ color: 'white' }, ...primaryBgColor }",
          :disabled="cart_busy"
        ) 
          |
          b-spinner.small(
            v-if="cart_busy",
            type="border",
            small,
            label="Loading..."
          )
          span(v-if="!cart_busy") {{ is_buyer ? $t("quote_modal.submit_for_po") : $t("quote_modal.submit_for_quote") }}

    .submited-response(v-else)
      .msg-thank-you
        span(v-if="is_buyer") Thank you for submitting your request for quote purchase order; it has been received.
        span(v-else) {{ $t("product.cart.thank_you") }}
      .title-resp(v-if="!is_buyer") {{ $t("product.cart.thank_you_for_submitting") }}
      .sub-title-resp(v-if="!is_buyer") {{ $t("product.cart.someone_from_our_team") }}
      .home-link 
        |
        |
        |
        b-button.text-light.pl-md-4.pr-md-4(
          variant="primary",
          @click="goToLanding()",
          :style="primaryBgColor"
        )
          span {{ $t("product.cart.continue_viewing_product") }}
</template>

<script>
import axios from 'axios';
import { mapState, mapActions, mapGetters } from 'pinia';
import { useCartStore } from '@/stores/cart-st';
import { useDbStore } from '@/stores/db-st';
import utils from '@/mixins/utils';
import TranslateValue from '@/components/TranslateValue.vue';
import { useAuthStore } from '@/stores/auth-st';

const SHIPPING_METHODS = [
  { id: 1, name: 'FedEx Ground' },
  { id: 2, name: 'FedEx Express Saver' },
  { id: 3, name: 'FedEx 2Day' },
  { id: 4, name: 'FedEx 2Day AM' },
  { id: 5, name: 'FedEx Std. Overnight' },
  { id: 6, name: 'FedEx Priority Overnight' },
  { id: 7, name: 'FedEx First Overnight' },
  { id: 8, name: 'FedEx Intl Economy' },
  { id: 9, name: 'FedEx Intl Priority' },
  { id: 10, name: 'FedEx Intl Priority Express' },
  { id: 11, name: 'UPS Ground' },
  { id: 12, name: 'UPS 2nd Day Air' },
  { id: 13, name: 'UPS 2nd Day Air AM' },
  { id: 14, name: 'UPS Next Day Air Saver' },
  { id: 15, name: 'UPS Next Day Air' },
  { id: 16, name: 'UPS Next Day Air Early' },
  { id: 17, name: 'UPS 3 Day Select' },
  { id: 18, name: 'Customer Arranged' },
  { id: 19, name: 'Will Call' },
  { id: 20, name: 'Hand Delivery' },
  { id: 21, name: 'DHL Express Worldwide' },
  { id: 22, name: 'USPS Priority Mail' },
  { id: 23, name: 'USPS Priority Mail Express' },
  { id: 24, name: 'USPS Ground Advantage' },
  { id: 25, name: 'USPS Priority Mail Intl' },
  { id: 26, name: 'USPS Priority Mail Express Intl' },
  { id: 27, name: 'USPS First-Class Pckg Intl Service' }
];

function toDateInputValue(dateObject){
  const local = new Date(dateObject);
  local.setMinutes(dateObject.getMinutes() - dateObject.getTimezoneOffset());
  return local.toJSON().slice(0,10);
};

export default {
  name: 'UserCart',
  components: {
    TranslateValue,
  },
  mixins: [utils],
  data() {
    return {
      search_term: '',
      
      shipmentMethods: SHIPPING_METHODS,
      cart_fields: [
        { key: 'sku', label: 'SKU' },
        { key: 'quantity', label: 'Qty.', class: 'text-right' },
        { key: 'name', label: 'Title' },
        { key: 'description', label: 'Description' },
        { key: 'opts', label: '' },
      ],
      my_cart_items: [],
      my_cart_contact: {
        name: '',
        email: '',
        phone: '',
        company: '',
        comments: '',
        preferred_contact: 'phone',
        purchase_order_date: toDateInputValue(new Date()),
        shipment_method: '',
        shipment_terms: 'EXW',
        purchase_order_number: '',
        ship_to: '',
      },
      cart_busy: false,
      cart_submitted: false,

      category_id: null, // from menu selects
    };
  },

  computed: {
    ...mapState(useCartStore, ['cart_contact', 'cart_items']),
    ...mapState(useDbStore, ['customer']),
    ...mapGetters(useDbStore, [
      'isMedtenCustomer',
      'primaryColor',
      'secondaryColor',
      'primaryBgColor',
      'secondaryBgColor',
      'primaryBgColorWithoutBorder',
      'secondaryBgColorWithoutBorder',
      'primaryBorder',
      'secondaryBorder',
    ]),
    ...mapState(useAuthStore, [
      'is_buyer',
    ]),
    ...mapState(useAuthStore, ["user_principal", "org_principal"]),
    showListPrice() {
      return this.customer?.display_list_price;
    },
    isCartEmpty() {
      return this.cart_items.length === 0;
    },
  },

  created() {
    this.hydrate();
    //- console.log('product**', this.cart_items);
  },

  mounted() {
    this.search_term = this.product_search_term;
  },

  watch: {
    product_search_term(now, then) {
      this.search_term = now;
    },
  },

  methods: {
    ...mapActions(useCartStore, [
      'emptyCart',
      'hydrateCart',
      'removeCartItem',
      'updateCartItem',
      'setCartContact',
    ]),
    closeModal(id) {
      this.$bvModal.hide(id);
      this.cart_submitted = false;
    },
    goToLanding() {
      this.cart_submitted = false;
      if (this.$route.name != 'Landing') this.$router.push({ name: 'Landing' });
      this.closeModal('cart');
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    getImgUrl(url) {
      if (url) return this.getFullUrl(url);
      return require(`@/assets/images/Default_Missing_Image.png`);
    },
    hydrate() {
      this.hydrateCart();
    },

    emptyTheCart() {
      this.emptyCart();
      this.setCartContact(null);
      this.initCart();
      this.$bvModal.hide('cart');
    },
    initCart() {
      this.hydrateCart();
      this.my_cart_items = this.cart_items || [];
      this.my_cart_contact = this.cart_contact || {
        name: '',
        email: '',
        phone: '',
        company: '',
        comments: '',
        preferred_contact: 'phone',
        purchase_order_date: toDateInputValue(new Date()),
        shipment_method: '',
        shipment_terms: 'EXW',
        ship_to: '',
        purchase_order_number: '',
      };
    },
    itemChanged(item) {
      if (!item) return;
      if (item.quantity == 0) {
        this.removeCartItem(item.id);
      } else {
        this.updateCartItem(item.id, item.quantity);
      }
    },
    removeItem(item) {
      this.removeCartItem(item.id);
    },
    async submitCart() {
      if (this.isCartEmpty) {
        this.$bvToast.toast(
          'Please add products to your cart before submitting for quote.',
          {
            title: 'Empty Cart',
            solid: true,
            toaster: 'b-toaster-top-center',
          }
        );
        return;
      }

      this.cart_busy = true;

      try {
        let is_valid = await this.$validator.validateAll();
        if (!is_valid) return;

        const fallbackUrl =
          process.env.VUE_APP_API_BASE_URL + '/email/requestQuote';
        const serviceUrl = this.customer?.cart_form_post_url
          ? this.customer.cart_form_post_url
          : fallbackUrl;
        const payload = {
          ...this.my_cart_contact,
        }
        if (!this.is_buyer) {
          delete payload.purchase_order_date;
          delete payload.shipment_method;
          delete payload.shipment_terms;
          delete payload.ship_to;
          delete payload.purchase_order_number;
        } else {
          payload.isPurchaseOrder = true;
          payload.userName = this.user_principal ? this.user_principal.first_name + ' ' + this.user_principal.last_name : null;
          payload.orgName = this.org_principal?.name;
        }
        await axios.post(serviceUrl, {
          items: this.cart_items,
          name: payload,
          for_email: this.customer ? this.customer.email : '',
          opcCustomerEmail: this.customer.email,
          opcCustomerId: this.customer.id,
          isMedtenCustomer: this.isMedtenCustomer,
          is_buyer: this.is_buyer,
          marketingRegionId: this.customer.marketing_region_id,
        });
        this.cart_submitted = true;
        this.emptyCart();
        this.setCartContact(null);
        this.initCart();
      } catch (err) {
        console.error(err);
      } finally {
        this.cart_busy = false;
      }
    },

    //- toggleCart(){
    //-   this.$bvModal.show('cart');
    //- },

    validateCart(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
  },
};
</script>
<style>
@media (min-width: 651px) and (max-width: 992px) {
  #cart .modal-dialog {
    max-width: 650px;
  }
}

@media (min-width: 576px) and (max-width: 650px) {
  #cart .modal-dialog {
    max-width: 576px;
  }
}

@media (max-width: 576px) {
  #cart .modal-dialog {
    margin: 0 !important;
  }
}
</style>
<style scoped lang="scss">
#cart {
  .cart-modal-header {
    .delete-cart {
      a {
        font-size: 1rem;
        color: #7f7f7f;
        :hover {
          color: #7f7f7f;
          text-decoration: none;
        }
      }
    }

    @media (max-width: 767px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .cart-modal-body {
    .cart-item-list-wrap {
      .item-list-heading {
        padding: 0.7rem 1rem 0.5rem 1rem;
        background: #f2f3f4;
      }
      .item-list {
        padding: 0.7rem 1rem 0.5rem 1rem;
        .item-name {
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 700;
          img {
            width: 50px;
          }
        }
        .list-item {
          font-size: 12px;
          font-weight: 500;
        }
        .item-qty {
          font-size: 12px;
          font-weight: 500;
          a {
            font-size: 20px;
          }
        }
      }
    }
    .cart-item-list-wrap-mobile {
      @media (min-width: 768px) {
        display: none;
      }
      .img-block {
        img {
          width: 100%;
        }
      }
      .data-block {
        .item-name {
          font-size: 12px;
          font-weight: 700;
        }
        .item-description {
          color: #272727;
          font-size: 12px;
          font-weight: 500;
        }
        .sku-qty {
          align-items: center;
          font-size: 12px;
          color: #272727;
          .qty {
            align-items: center;
            input {
              font-weight: 600;
            }
          }
        }
      }
    }
    .contact-info-block {
      font-size: 14px;
      input {
        font-size: 14px;
      }
      #inquary-phone input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      #inquary-phone input[type='number'] {
        -moz-appearance: textfield;
      }
    }
    .submited-response {
      text-align: center;
      margin: 3rem;
      @media (max-width: 767px) {
        margin: 3rem 1rem;
      }
      .msg-thank-you {
        font-size: 36px;
        font-weight: 500;
        color: #68c3c5;
        padding: 0.5rem;
      }
      .title-resp {
        padding-top: 0.5rem;
        font-size: 14px;
      }
      .sub-title-resp {
        padding: 0.5rem;
        font-size: 14px;
      }
      .home-link {
        padding: 0.5rem;
      }
    }
  }
}
</style>
