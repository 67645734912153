<template lang="pug">
//- // branding
//-   // loggin
//- /// ---accessories (your price, listPrice)
//- //// ---parts (your price, factory price)
//- // Not loggin
//- /// ---accessories (list price)
//- //// ---parts ( request quote)
b-col(cols="6", md="12", xl="6")
  div(v-if="!isMedtenCustomer")
    .price-group
      span.item-label
        span.dot(:style="primaryBgColor")
        | {{ $t("search.label.price") }}:
      span.price-value.ml-2(
        v-if="!shouldShowListPrice",
        @click="showBrandingListPrice = true"
      ) 
        |
        |
        |
        span.request-list-price {{ $t("global.label.request_quote") }}
      span.price-value.ml-2(
        v-else-if="!isLoggedIn",
        :style="primaryColor",
        :class="listPriceClass"
      ) {{  translatePrice(product,"list_price") ? (getCurrency + translatePrice(product,"list_price").toFixed(2)) : ( " " + $t('global.label.request_quote'))  }}
      span.price-value.ml-2(
        v-else,
        :style="primaryColor",
        :class="listPriceClass"
      ) {{ listPrice(this.buyer_price_info,this.customer.marketing_region_id) ? (getCurrency + listPrice(this.buyer_price_info,this.customer.marketing_region_id).toFixed(2)) : ( " " + $t('global.label.request_quote'))  }}
  div(v-else)
    .price-group(v-if="showYourPrice")
      span.item-label
        span.dot(:style="primaryBgColor")
        | Your Price
        span(v-if="showMOQ") &nbsp;(MOQ: {{ buyer_price_info.minimum_quantity }})
        | : 
      span.price-value.mr-xl-5.ml-xl-auto.ml-md-2(
        :style="primaryColor",
        v-if="product.outsourced && !isPart"
      ) {{  yourPrice ? (getCurrency + yourPrice.toFixed(2)) : (" " + $t('global.label.request_quote'))  }}
      span.price-value.mr-xl-5.ml-xl-auto.ml-md-2(
        :style="primaryColor",
        v-else
      ) {{  yourPrice? (getCurrency + yourPrice.toFixed(2)) : (" " + $t('global.label.request_quote'))  }}
    .price-group(v-if="showFactoryPrice")
      span.item-label
        span.dot(:style="primaryBgColor")
        | {{ $t("search.label.factory_price") }}:
      span.price-value.mr-xl-5.ml-xl-auto.ml-md-2.strikethrough(
        :style="primaryColor",
        v-if="factoryPrice(buyer_price_info, customer.marketing_region_id)"
      ) {{ getCurrency + factoryPrice(buyer_price_info, customer.marketing_region_id).toFixed(2) }}
      span.price-value.mr-xl-5.ml-xl-auto.ml-md-2(
        :style="primaryColor",
        v-else
      ) {{  (" " + $t('global.label.request_quote'))  }}
    .price-group(v-if="showListPrice")
      span.item-label
        span.dot(:style="primaryBgColor")
        | {{ $t("search.label.price") }}:
      span.price-value.ml-2(:style="primaryColor", :class="listPriceClass") {{  translatePrice(product,"list_price") ? (getCurrency + translatePrice(product,"list_price").toFixed(2)) : (" " + $t('global.label.request_quote'))  }}
    .price-desc(v-if="showDiscountQuote") {{ $t("search.label.discount_quote") }}
    .price-group(v-if="showRequestQuote")
      span.item-label
        span.dot(:style="primaryBgColor")
        | {{ $t("search.label.price") }}:
      span.price-value.ml-2
        |
        |
        span.request-list-price {{ $t("global.label.request_quote") }}
</template>
<script>
import utils from "@/mixins/utils";
import { useDbStore } from "../stores/db-st";
import { mapActions, mapState, mapGetters } from "pinia";
export default {
  mixins: [utils],
  props: {
    productSuppliers: {
      type: Array,
    },
    isMedtenCustomer: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
    isLoggedIn: {
      type: Boolean,
      required: true,
    },
    isPart: {
      type: Boolean,
      required: true,
    },
    primaryColor: {
      type: Object,
      default: null,
    },
    primaryBgColor: {
      type: Object,
      default: null,
    },
    buyer_price_info: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showBrandingListPrice: false,
    };
  },
  computed: {
    ...mapState(useDbStore, ["customer"]),
    listPriceClass() {
      if (this.buyer_price_info) {
        return "strikethrough";
      }
      return "";
    },
    getDealerPrice() {
      const region = this.customer.marketing_region_id;
      const mrMapping = {
        1: "us",
        2: "eu",
        3: "zh",
      };
      const result = this.productSuppliers.find((item) => {
        if (item.marketing_region_id === region) {
          return true;
        } else {
          return false;
        }
      });
      if (result) {
        return result["dealer_price_" + mrMapping[region]];
      }
      return null;
    },
    shouldShowListPrice() {
      return this.getShouldShowListPrice(
        this.isPart,
        this.isLoggedIn,
        this.showBrandingListPrice
      );
    },
    yourPrice() {
      console.log("isPart", this.isPart);
       if(this.buyer_price_info && this.buyer_price_info.priorityPrices && this.buyer_price_info.priorityPrices.length>0){
        const priorityPrice= this.buyer_price_info?.priorityPrices.find((item)=>item.marketing_region_id===this.customer.marketing_region_id);
        return priorityPrice.bulk_price;
       }
      else if (
        (this.isPart || !this.isPart) &&
        (!this.product.outsourced||this.product.outsourced )&&
        this.buyer_price_info &&
        this.buyer_price_info?.discount_price
      ) {
        console.log(this.buyer_price_info?.discount_price);
        return this.buyer_price_info?.discount_price;
      } else if (
        (this.isPart || !this.isPart) &&
        !this.product.outsourced &&
        this.buyer_price_info &&
        this.buyer_price_info?.discount_percentage
      ) {
        console.log(this.buyer_price_info?.discount_percentage);
        if (this.customer.marketing_region_id == "1") {
          return this.buyer_price_info?.price.discounted_factory_price_us;
        } else if (this.customer.marketing_region_id == "2") {
          return this.buyer_price_info?.price.discounted_factory_price_eu;
        } else if (this.customer.marketing_region_id == "3") {
          return this.buyer_price_info?.price.discounted_factory_price_cn;
        }
      } else if (!this.product.outsourced) {
        if (this.customer.marketing_region_id == "1") {
          return this.buyer_price_info?.price.factory_price_us;
        } else if (this.customer.marketing_region_id == "2") {
          return this.buyer_price_info?.price.factory_price_eu;
        } else if (this.customer.marketing_region_id == "3") {
          return this.buyer_price_info?.price.factory_price_cn;
        }
      } else if (this.product.outsourced) {
        if (this.customer.marketing_region_id == "1") {
          return this.buyer_price_info?.price.dealer_price_us;
        } else if (this.customer.marketing_region_id == "2") {
          return this.buyer_price_info?.price.dealer_price_eu;
        } else if (this.customer.marketing_region_id == "3") {
          return this.buyer_price_info?.price.dealer_price_cn;
        }
      }

      //  else if(!this.product.outsourced && (this.isPart||this.buyer_price_info?.parentCatId=="2")){
      //          if(this.customer.marketing_region_id=='1'){
      //         return this.buyer_price_info?.price.dealer_price_us
      //         }else if (this.customer.marketing_region_id=='2'){
      //         return this.buyer_price_info?.price.dealer_price_eu
      //       }else if (this.customer.marketing_region_id=='3'){
      //       return this.buyer_price_info?.price.dealer_price_cn
      //       }

      //     }
    },
    //- // branding
    //-   // loggin
    //- /// ---accessories (your price, listPrice)
    //- //// ---parts (your price, factory price)
    //- // Not loggin
    //- /// ---accessories (list price)
    //- //// ---parts ( request quote)
    showFactoryPrice() {
      console.log("isPart", this.isPart);
      if (
        this.isLoggedIn &&
        this.buyer_price_info?.parentCatId == "2" &&
        !this.product.outsourced
      ) {
        return false;
      }
      return false;
    },
    showYourPrice() {
      if (this.isLoggedIn) {
        return true;
      }
      return false;
    },
    showListPrice() {
      if (!this.isLoggedIn) {
        return !this.isPart
      } else if (this.buyer_price_info?.parentCatId == "1"){
        return true;
      }
    },
    showDiscountQuote() {
      if (!this.isPart && this.isLoggedIn) {
        return false;
      }
      if (this.showListPrice) {
        return true;
      }
      return false;
    },
    showRequestQuote() {
      if (!this.isLoggedIn && this.isPart) {
        return true;
      }
    },
    showMOQ() {
      return this.buyer_price_info && 
             this.buyer_price_info.minimum_quantity && 
             this.buyer_price_info.minimum_quantity > 1;
    },
  },
};
</script>
<style scoped lang="scss">
/* for price */
.price-group {
  display: inherit;
  align-items: center;
  width: 300px;

  .price-value {
    margin-left: auto;
    color: #38bcd9;
    font-size: 14px;
    font-weight: 600;

    span {
      font-size: 14px;
      font-weight: 400;
      color: #525252;
    }

    .request-list-price {
      color: #8d8601;
      font-weight: bold;
    }
  }
}

.price-desc {
  margin-left: 1.1rem;
  color: #14a757;
  font-size: 14px;
  font-weight: 400;
}

.strikethrough {
  text-decoration: line-through;
}
</style>
