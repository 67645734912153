<template lang="pug">
#mission-promise-statement
  b-container.mt-3.mission-promise-statement(
    fluid,
    :class="{ 'search-filter-padding': isSearch }",
    v-if="customer"
  )
    .text-center.inner-wrap-1
      p {{ translateText(customer, "primary_text", true) }}
    .text-center.inner-wrap-2 
      |
      p {{ translateText(customer, "secondary_text", true) }}

    b-row.p-md-4.m-2.statement-block
      b-col.d-flex.statement-item(cols="6", sm="6", lg="3")
        .left-block
          b-img.custom-arrow(
            v-if="!isMedtenCustomer",
            src="@/assets/images/landing/fast-shipping-branded.svg"
          )
          b-img.custom-arrow(
            v-else,
            src="@/assets/images/landing/fast-shipping.svg"
          )
        .right-block.pl-2
          .title-text {{ $t("search.verbiage.fast_shipping") }}
          .sub-title-text {{ $t("product.cart.local_inventory") }}
      b-col.d-flex.statement-item(cols="6", sm="6", lg="3")
        .left-block
          b-img.custom-arrow(
            v-if="!isMedtenCustomer",
            src="@/assets/images/landing/savings_branded.svg"
          )
          b-img.custom-arrow(v-else, src="@/assets/images/landing/savings.svg")
        .right-block.pl-2
          .title-text {{ $t("search.label.upto_50%_savings") }}
          .sub-title-text {{ $t("search.verbiage.direct_supply_from_manufacturer") }}
      b-col.d-flex.statement-item(cols="6", sm="6", lg="3")
        .left-block
          b-img.custom-arrow(
            v-if="!isMedtenCustomer",
            src="@/assets/images/landing/guaranteed_branded.svg"
          )
          b-img.custom-arrow(
            v-else,
            src="@/assets/images/landing/guaranteed.svg"
          )
        .right-block.pl-2
          .title-text {{ $t("search.verbiage.quality_guarantee") }}
          .sub-title-text {{ $t("search.verbiage.fda_clearance_ce_certified") }}
      b-col.d-flex.statement-item(cols="6", sm="6", lg="3")
        .left-block
          b-img.custom-arrow(
            v-if="!isMedtenCustomer",
            src="@/assets/images/landing/easy-returns_branded.svg"
          )
          b-img.custom-arrow(
            v-else,
            src="@/assets/images/landing/easy-returns.svg"
          )
        .right-block.pl-2
          .title-text {{ $t("search.verbiage.100_satisfaction") }}
          .sub-title-text {{ $t("search.verbiage.hassle_free_30-day_return_policy") }}
</template>

<script>
import { mapState } from "pinia";
import { useBaseStore } from "@/stores/base-st";
import { useDbStore } from "@/stores/db-st";
import utils from "@/mixins/utils";
export default {
  name: "MissionPromiseStatement",
  components: {},
  mixins: [utils],
  props: {
    isSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },

  computed: {
    ...mapState(useBaseStore, ["message"]),
    ...mapState(useDbStore, ["customer", "isMedtenCustomer"]),
  },

  created() {},

  mounted() {},

  watch: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
#mission-promise-statement {
  .mission-promise-statement {
    padding-top: 3rem;
    box-shadow: 0px -4px 3px rgb(102 102 102 / 11%);
    &.search-filter-padding {
      padding-left: 25vw;
    }
    .inner-wrap-1 {
      display: flex;
      justify-content: center;
      p {
        width: 45vw;
        font-size: 24px;
        b {
          color: #38bcd9;
        }
        @media (max-width: 767px) {
          width: auto;
          font-size: 16px;
        }
        @media (min-width: 768px) and (max-width: 1200px) {
          width: 75vw;
        }
      }
    }
    .inner-wrap-2 {
      display: flex;
      justify-content: center;
      p {
        width: 65vw;
        font-size: 14px;
        color: #00000080;
        @media (max-width: 767px) {
          width: auto;
          font-size: 12px;
        }
        @media (min-width: 768px) and (max-width: 1200px) {
          width: 85vw;
        }
      }
    }
    .statement-block {
      .statement-item {
        align-items: center;
        padding: 0.5rem;
        .right-block {
          .title-text {
            font-weight: 600;
            font-size: 18px;
            color: #00000080;
          }
          .sub-title-text {
            font-size: 14px;
            color: #00000080;
          }
        }
      }
    }
    @media (max-width: 767px) {
      .statement-block {
        .statement-item {
          align-items: flex-start !important;
          padding: 0.5rem 0.3rem !important;
          margin: 0px !important;
          .left-block {
            img {
              width: 40px !important;
            }
          }
          .right-block {
            .title-text {
              font-weight: 600;
              font-size: 14px !important;
            }
            .sub-title-text {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
  @media (max-width: 567px) {
    .mission-promise-statement {
      padding-top: 2rem;
    }
  }
}
@media (max-width: 1000px) {
  #mission-promise-statement {
    .mission-promise-statement {
      &.search-filter-padding {
        padding-left: 1vw;
      }
    }
  }
}
</style>