<template lang="pug">
span(v-html="cleanedTranslateText")
</template>
<script>
export default {
  name: 'TranslateValue',
  props: {
    sourceObj: {
      type: Object,
      default: null,
    },
    objKey: {
      type: String,
      default: 'name',
    },
  },
  computed: {
    cleanedTranslateText() {
      const translatedText = this.translateText;
      // Remove <mark> tags from the translated text
      const cleanedText = translatedText.replace(/<\/?mark>/g, '');
      return cleanedText;
    },
    translateText() {
      const currentLocale = this.$i18n.locale;
      const validLocale = ['en', 'zh'];
      const localeToUse =
        validLocale.find((item) => item === currentLocale) || 'en';
      const propKey = this.objKey + '_' + localeToUse;
      if (this.sourceObj) {
        return this.sourceObj[propKey] || '';
      }
      return '';
    },
  },
};
</script>
